import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Swiper from 'react-id-swiper';
import Vimeo from '@u-wave/react-vimeo';
import '@/styles/swiper.min.css';
import { useTranslation } from 'react-i18next';

const IndexPage = () => {
  const { t } = useTranslation();
  const [hidesCover, setHidesCover] = useState(false);
  const [hidesVideo, setHidesVideo] = useState(true);
  const [player, setPlayer] = useState();
  const [isFirstTimePlayingVideo, setIsFirstTimePlayingVideo] = useState(true);

  const params = {
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      dynamicBullets: true
    },

    direction: 'horizontal',
    loop: true,
    spaceBetween: 30,
    slidesPerView: 3,
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      // when window width is >= 480px
      768: {
        slidesPerView: 2,
        spaceBetween: 30
      },
      // when window width is >= 640px
      1024: {
        slidesPerView: 3,
        spaceBetween: 30
      }
    }
  }

  return (
    <Layout>
      <SEO title="Home" />
      <section className="homepage-banner">
        <Vimeo
          className={`hero-video ${hidesVideo ? "hidden": ""}`}
          video="443652854"
          onPlay={() => {
            if (window.innerWidth > 768) {
              document.querySelector(".menu-left").setAttribute("hidden", true);
            }
          }}
          onEnd={() => {
            setHidesCover(false);
            setHidesVideo(true);
            if (window.innerWidth > 768) {
              document.querySelector(".menu-left").removeAttribute("hidden");
            }
          }}
          onReady={(player) => {
            setPlayer(player);
            if (isFirstTimePlayingVideo) {
              setIsFirstTimePlayingVideo(false);
            }
          }}
        />
        <div className="cover" hidden={hidesCover}>
          <img src="/images/cover_image.jpg" alt="Yitnow" />
          <div className="play-button" onClick={() => {
            setHidesCover(true);
            setHidesVideo(false);
            if (!isFirstTimePlayingVideo && player) {
              player.play();
            }
          }}></div>
        </div>
      </section>

      <section className="homepage-download-banner">
        <a href="https://qrs.ly/mibtguh" target="_blank">
          <span class="cta-text">↓↓↓ Download now ↓↓↓</span>
          <span>
            <img src="/images/app_store.jpg" alt="Yitnow on App Store"/>
            <img src="/images/play_store.jpg" alt="Yitnow on Google Play"/>
          </span>
        </a>
      </section>

      <section className="homepage-intro" id="casestudies">
        <h2>FOR THE BORED AND THE BRAVE</h2>
        <div className="divider"></div>

        <div className="img-row">
          <div className="col">
            <h5>Dont get Left at Home</h5>
            <p>We've all been there; your mates are:</p>
            <ul>
              <li>working late </li>
              <li>having a quiet night in with the missus... or mister </li>
              <li>on the other side of town</li>
            </ul>
            <br />DONT RESIGN YOURSELF TO A QUIET NIGHT IN
          <br />
            <br /> YITNOW 熱鬧 has the answer, with YITNOW 熱鬧 you can find other people like you nearby and get fun you deserve.
        </div>
          <div className="col">
            <div className="img-wrapper">
              <img src="/images/homepage-intro-1.jpg" alt="drinking, night-out, bars" />
            </div>
          </div>
        </div>

        <div className="img-row">
          <div className="col">
            <div className="img-wrapper">
              <img src="/images/homepage-intro-2.jpg" alt="beach, ocean, running" />
            </div>
          </div>
          <div className="col">
            <h5>Find Friends in Town</h5>
            <p>Walking through town and wondering who else is nearby, and keen for a few swifties?
            <br />
              <br />With YITNOW 熱鬧 you can see which of your mates are having a good time nearby and want to meet up.
          </p>
          </div>
        </div>

        <div className="img-row">
          <div className="col">
            <h5>Meet People who fit Your Schedule</h5>
            <p>Maybe you:</p>
            <ul>
              <li>work shifts </li>
              <li>are a new mother </li>
              <li>pull late nighters at the office</li>
            </ul>
            <br /><em>And this makes it harder for you to meet people at times that suit you.</em>
            <br />
            <br /> With YITNOW 熱鬧 you can meet people who are available when you are.
        </div>
          <div className="col">
            <div className="img-wrapper">
              <img src="/images/homepage-intro-3.jpg" alt="bars, drinking, night-out" />
            </div>
          </div>
        </div>

        <div className="img-row">
          <div className="col">
            <div className="img-wrapper">
              <img src="/images/homepage-intro-4.jpg" alt="lady, party, fun" />
            </div>
          </div>
          <div className="col">
            <h5>Network a Lunch Date</h5>
            <p>Fancy getting out of the office and meeting someone new for lunch? Whether its business or pleasure, its more interesting than a Pret sandwich for one.
            <br />
              <br />With YITNOW 熱鬧 you can see other hungry office drones nearby.
          </p>
          </div>
        </div>
      </section>

      <section className="homepage-full-section background-overlay" id="whatsgoingon">
        <h2>SOMEWHERE TO MEET</h2>
        <div className="divider"></div>
        <p>Impress the new people you meet with the best venues and experiences within walking distance.</p>
        <p>YITNOW 熱鬧 provides you with people nearby and the places to take them.</p>
      </section>

      <section className="homepage-final-section">
        <h2>Find what you need within walking distance... right now</h2>
        <div className="divider"></div>
        <p><strong>Tell other people what youre looking for with your icebreaker message, and tell YITNOW 熱鬧 what sort of venue you’re looking for with tags. From comedy to Kombucha if its nearby we’ve got you covered.</strong></p>
        <p><strong>Check out the slideshow below to see how YITNOW 熱鬧 can work for you.</strong></p>

        <Swiper

          wrapperClass="swiper-wrapper"

          {...params}
        >
          <div className="swiper-slide">
            <figure className="swiper-slide-inner">
              <img src="/images/Activation-Page.png" alt="1. Introduce yourself, and what you want in the icebreaker message & below this tell the app what sort of venues and activities you need with tags." />
              <figcaption>
                <h5>Icebreaker</h5>
                <p>Introduce yourself, and what you want in the icebreaker message.<br /><br /> Below this tell the app what sort of venues and activities you need with tags.</p>
              </figcaption>
            </figure>
          </div>
          <div className="swiper-slide">
            <figure className="swiper-slide-inner">
              <img className="swiper-slide-image" src="/images/AllChats-Page-with-Advert-1.jpg" alt="2. Up to Five venues will tell you whats going on in your area." />
              <figcaption>
                <h5>Explore Venues</h5>
                <p>Up to Five venues will tell you whats going on in your area.</p>
              </figcaption>
            </figure>
          </div>
          <div className="swiper-slide">
            <figure className="swiper-slide-inner">
              <img className="swiper-slide-image" src="/images/AllChats-Page-with-Chats.jpg" alt="3. And you can see all the other people nearby who want to hang out!" />
              <figcaption>
                <h5>Discover People Nearby</h5>
                <p>You can see all the other people nearby who want to hang out!</p>
              </figcaption>
            </figure>
          </div>
          <div className="swiper-slide">
            <figure className="swiper-slide-inner">
              <img className="swiper-slide-image" src="/images/Messaging-Page-Conversation.png" alt="4. Chat to the people who interest you." />
              <figcaption>
                <h5>Find Your New Best Friend</h5>
                <p>Chat with people and forge a long-lasting bond!</p>
              </figcaption>
            </figure>
          </div>
          <div className="swiper-slide">
            <figure className="swiper-slide-inner">
              <img className="swiper-slide-image" src="/images/slide-5.jpg" alt="5. Meet up & have fun guys!!" />
              <figcaption>
                <h5>Finally....</h5>
                <p>Meet up and have fun guys!!</p>
              </figcaption>
            </figure>
          </div>

        </Swiper>

        <div>
          <p>YITNOW 熱鬧 will send you a maximum of 5 suggestions that match your tags so you know whats happening right now around you. </p>
          <p>Say goodbye to FOMO, say hello to going out on a whim.</p>
          <p>Experience your local area as never before.</p>

          <a href="promote/" className="button final-cta" role="button">
            Promote Something
          </a>
          </div>
      </section>


    </Layout>
  )
};

export default IndexPage
